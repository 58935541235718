import HomePageV2 from "@/components/pages/HomePageV2";
import { createSSG } from "@/server/ssg";
import type { GetStaticProps, InferGetStaticPropsType, NextPage } from "next";
import Head from "next/head";

export const getStaticProps: GetStaticProps = async () => {
  const ssg = await createSSG({ session: null });

  await Promise.all([
    ssg.timeline.getRecent.prefetch(),
    ssg.review.recentReviews.prefetchInfinite({}),
    ssg.venue.getTrending.prefetch(),
    ssg.venue.getRecent.prefetch(),
    ssg.venue.summaryByState.prefetch(),
  ]);

  return {
    props: {
      trpcState: ssg.dehydrate(),
    },
    // 5 minutes
    revalidate: 60 * 5,
  };
};

const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = () => {
  return (
    <>
      <Head>
        <title>GigIntel</title>
        <meta
          name="description"
          content="Theatre reviews made by the community, for the community."
        />
      </Head>
      <HomePageV2 />
    </>
  );
};

export default Home;
