import {
  GOAL_PERCENT_INT,
  GOAL_STATES_FULL_NAMES,
} from "@/utils/goal-constants";
import { trpc } from "@/utils/trpc";
import Link from "next/link";
import { useMemo } from "react";
import { Button } from "../ui/button";

export const GoalHero = () => {
  const aggs = trpc.venue.summaryByState.useQuery();

  const { percentComplete, totalCount, count } = useMemo(() => {
    const statesData = aggs.data?.states.filter((d) =>
      GOAL_STATES_FULL_NAMES.includes(d.state),
    );

    if (!statesData) {
      return { percentComplete: 0, totalCount: 0, count: 0 };
    }

    const total = statesData.reduce((acc, d) => acc + d.totalCount, 0);
    const reviewed = statesData.reduce((acc, d) => acc + d.count, 0);
    return {
      percentComplete: Math.floor((reviewed / total) * 100),
      totalCount: total,
      count: reviewed,
    };
  }, [aggs.data?.states]);

  return (
    <div className="flex min-h-[200px] flex-col items-center justify-center">
      <div className="mb-4 text-3xl font-extrabold text-violet-800 md:text-4xl">
        We have a mission!
      </div>
      <div className="mb-3 max-w-[700px] text-xl text-gray-500">
        Help us review <span className="font-bold">{GOAL_PERCENT_INT}%</span> of
        all theatres in NY tristate area.
      </div>
      <div className="relative mb-3 w-full md:w-3/4 lg:w-1/2">
        <div className="rounded-3xl bg-gray-100">
          <div>
            <div
              style={{ width: `${percentComplete}%` }}
              className={`flex h-2 rounded bg-violet-600 text-white`}
            />
          </div>
        </div>
        <div className="absolute left-0 right-0 top-0 flex">
          <div
            style={{ width: `${GOAL_PERCENT_INT}%` }}
            className="h-2 border-r-2 border-violet-800"
          >
            &nbsp;
          </div>
          <div className="-ml-3 mt-2 text-sm text-gray-400">goal</div>
        </div>
        <div className="text-sm text-gray-400">
          {count} / {totalCount} theatres in NY tristate area
        </div>
      </div>
      <div className="flex">
        <Button variant="ghost" asChild>
          <Link href={"/goal"}>Visit our goal page</Link>
        </Button>
      </div>
    </div>
  );
};
