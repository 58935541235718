import cn from "@/utils/cn";
import { Slot } from "@radix-ui/react-slot";
import React, {
  Children,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

export const Carousel: React.FC<{
  className?: string;
  children?:
    | React.ReactElement<typeof CarouselPage>
    | React.ReactElement<typeof CarouselPage>[];
  page: number;
  onChange?: (page: number) => void;
  previousButton: React.ReactNode;
  nextButton: React.ReactNode;
}> = (props) => {
  const [clickedOnNext, setClickedOnNext] = useState(false);
  const childRefs = useRef<Record<number, HTMLElement>>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const setChildRef = (node: HTMLElement | null, index: number) => {
    if (!node) {
      return;
    }
    childRefs.current[index] = node;
  };

  const recalculateHeight = useCallback(() => {
    if (!containerRef.current) {
      return;
    }
    const activeChildRef = childRefs.current[props.page];
    if (!activeChildRef) {
      return;
    }

    containerRef.current.style.height = `${activeChildRef.offsetHeight}px`;
  }, [props.page]);

  useLayoutEffect(() => {
    recalculateHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recalculateHeight, props.children]);

  useEffect(() => {
    function onResize() {
      recalculateHeight();
    }
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [recalculateHeight]);

  return (
    <div
      ref={containerRef}
      className={cn(
        "relative overflow-hidden px-10",
        clickedOnNext && "transition-all duration-500",
        props.className
      )}
    >
      <Slot
        className={cn(
          "absolute left-0 top-1/2 z-10 -translate-y-1/2 rounded-full px-1",
          props.page === 0 && "hidden"
        )}
        onClick={() => {
          setClickedOnNext(true);
          props.onChange?.(Math.max(props.page - 1, 0));
        }}
      >
        {props.previousButton}
      </Slot>
      {Children.map(props.children, (child, index) => (
        <Slot
          ref={(node) => {
            setChildRef(node, index);
          }}
          className={cn(
            "transition-all duration-500",
            index < props.page && "-translate-x-full ",
            index === props.page && "translate-x-0 opacity-100",
            index > props.page && "translate-x-full ",
            index !== props.page && "pointer-events-none opacity-0"
          )}
        >
          {child}
        </Slot>
      ))}
      <Slot
        className={cn("absolute right-0 top-1/2 z-10 -translate-y-1/2")}
        onClick={() => {
          setClickedOnNext(true);
          props.onChange?.(props.page + 1);
        }}
      >
        {props.nextButton}
      </Slot>
    </div>
  );
};

export const CarouselPage = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "absolute left-0 right-0 top-0 overflow-hidden px-10",
        className
      )}
      {...props}
    />
  );
});

CarouselPage.displayName = "CarouselPage";
