import Footer from "@/components/Footer";
import Main from "@/components/Main";
import ProfileBox from "@/components/ProfileBox";
import Logo from "@/components/ui/Logo";
import { VenueShape } from "@/server/api/mappers";
import cn from "@/utils/cn";
import { trpc } from "@/utils/trpc";
import PlusIcon from "@heroicons/react/20/solid/PlusIcon";
import TrendingIcon from "@heroicons/react/24/outline/ArrowTrendingUpIcon";
import RecentIcon from "@heroicons/react/24/outline/ClockIcon";
import MapPinIcon from "@heroicons/react/24/outline/MapPinIcon";
import SafetyIcon from "@heroicons/react/24/outline/ShieldCheckIcon";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import cs from "classnames";
import { ChevronLeft, ChevronRight, Loader2 } from "lucide-react";
import Link from "next/link";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  BsCircleFill as CircleFillIcon,
  BsCircle as CircleIcon,
} from "react-icons/bs";
import {
  Highlight,
  InstantSearch,
  useHits,
  useSearchBox,
  useSortBy,
} from "react-instantsearch";
import { usePopper } from "react-popper";
import { Carousel, CarouselPage } from "../carousel";
// import { LandingCarousel } from "../landing/landing-carousel";
import Rating from "../ui/Rating";
import { Button } from "../ui/button";
import { searchClient, searchIndexes } from "./HomePage";
import { GoalHero } from "../goal/goal-hero";

const HomePageV2: React.FC = () => {
  const trending = trpc.venue.getTrending.useQuery();
  const recentReviewsQuery = trpc.review.recentReviews.useInfiniteQuery(
    {},
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      getPreviousPageParam: (firstPage) => firstPage.previousCursor,
    },
  );
  const recentVenuesQuerys = trpc.venue.getRecent.useQuery();

  const [currentPage, setCurrentPage] = useState(0);

  return (
    <>
      <InstantSearch
        searchClient={searchClient}
        indexName={searchIndexes.sortedByName}
      >
        <Main as="div">
          <div className="mb-5 flex w-full items-center justify-between">
            <Logo />
            <div className="flex items-center space-x-5">
              <Link
                href="/safety"
                className="flex items-center space-x-1 font-bold text-gray-600 hover:text-gray-800"
              >
                <SafetyIcon className="w-5" />
                <span>Safety</span>
              </Link>
              <ProfileBox />
            </div>
          </div>
          {/* <section className="flex items-center justify-center pt-10">
            <LandingCarousel />
          </section> */}
          <GoalHero />
        </Main>

        <Main className="space-y-4">
          <div className="flex justify-center">
            <SearchBox />
          </div>
          <div className="space-y-10">
            <div>
              <h4 className="mb-2 mt-5 flex space-x-2 font-extrabold uppercase text-violet-800">
                <RecentIcon className="w-4" />
                <span>New Reviews</span>
              </h4>
              <Carousel
                className=""
                page={currentPage}
                onChange={async (nextPage) => {
                  if (
                    !recentReviewsQuery.data?.pages[nextPage] &&
                    nextPage > currentPage
                  ) {
                    await recentReviewsQuery.fetchNextPage();
                  }
                  setCurrentPage(nextPage);
                }}
                previousButton={
                  <Button
                    variant={"ghost"}
                    className={cn("h-10 w-10 rounded-full p-0")}
                    disabled={recentReviewsQuery.isFetching}
                  >
                    {recentReviewsQuery.isFetchingPreviousPage ? (
                      <Loader2 className="w-10 animate-spin" />
                    ) : (
                      <ChevronLeft className="w-10" />
                    )}
                  </Button>
                }
                nextButton={
                  <Button
                    variant={"ghost"}
                    className={cn("h-10 w-10 rounded-full p-0")}
                    disabled={
                      recentReviewsQuery.isFetching ||
                      (!recentReviewsQuery.hasNextPage &&
                        !recentReviewsQuery.data?.pages[currentPage + 1])
                    }
                  >
                    {recentReviewsQuery.isFetchingNextPage ? (
                      <Loader2 className="w-10 animate-spin" />
                    ) : (
                      <ChevronRight className="w-10" />
                    )}
                  </Button>
                }
              >
                {recentReviewsQuery.data?.pages.flatMap((page, index) => (
                  <CarouselPage
                    key={`page-${index}`}
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
                  >
                    {page.items.map((review) => (
                      <Link
                        href={`/theatre/${review.venueId}?reviewId=${review.id}`}
                        key={review.id}
                        className="flex flex-col items-center justify-center rounded p-6 hover:border-violet-400 hover:bg-violet-100 hover:shadow-lg"
                      >
                        <span className="text-center text-lg font-bold">
                          {review?.venue?.name}
                        </span>
                        <span className="mt-[-4px]">
                          <Rating starDimension="14px" rating={review.rating} />
                        </span>
                        <span className="block max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-xs text-gray-500">
                          {review.comment}
                        </span>
                      </Link>
                    ))}
                  </CarouselPage>
                ))}
              </Carousel>
              <div className="flex justify-center space-x-2">
                {recentReviewsQuery.data?.pages.map((page, index) =>
                  currentPage === index ? (
                    <CircleFillIcon
                      key="circle-fill"
                      className="w-[6px] text-gray-900"
                    />
                  ) : (
                    <button
                      key={`dot-${index}`}
                      onClick={() => setCurrentPage(index)}
                    >
                      <CircleIcon className={"w-[6px] text-gray-900"} />
                    </button>
                  ),
                )}
                {recentReviewsQuery.hasNextPage ? (
                  <CircleFillIcon className={cn("w-[6px] text-gray-200")} />
                ) : null}
              </div>
            </div>
            <div>
              <h4 className="mb-2 flex space-x-2 font-extrabold uppercase text-violet-800">
                <TrendingIcon className="w-4" />
                <span>Trending Theatres</span>
              </h4>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {trending.data?.map((venue) => (
                  <Link
                    href={`/theatre/${venue.id}`}
                    key={venue?.id}
                    className="flex flex-col items-center justify-center rounded p-6 hover:border-violet-400 hover:bg-violet-100 hover:shadow-lg"
                  >
                    <span className="text-center text-lg font-bold">
                      {venue.name}
                    </span>
                    <span className="text text-gray-500">
                      <Rating
                        className="mt-[-4px]"
                        starDimension="14px"
                        rating={venue.averageRating}
                      />
                    </span>
                    <span className="text-xs text-gray-500">
                      {venue.totalRatingCount} Review(s)
                    </span>
                  </Link>
                ))}
              </div>
            </div>
            <div>
              <h4 className="mb-2 mt-5 flex space-x-2 font-extrabold uppercase text-violet-800">
                <MapPinIcon className="w-4" />
                <span>Newly Added</span>
              </h4>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {recentVenuesQuerys.data?.map((venue) => (
                  <Link
                    href={`/theatre/${venue.id}`}
                    key={venue?.id}
                    className="flex flex-col items-center justify-center rounded p-6 hover:border-violet-400 hover:bg-violet-100 hover:shadow-lg"
                  >
                    <span className="text-center text-lg font-bold">
                      {venue.name}
                    </span>
                    <span className="text text-gray-500">
                      <Rating
                        className="mt-[-4px]"
                        starDimension="14px"
                        rating={venue.averageRating}
                      />
                    </span>
                    <span className="text-xs text-gray-500">
                      {venue.totalRatingCount} Review(s)
                    </span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <Footer />
        </Main>
      </InstantSearch>
    </>
  );
};

const MAX_SEARCH_HITS = 5;

const SearchBox: React.FC = () => {
  const [isFocused, setIsFocused] = useState(false);
  const { query, refine } = useSearchBox();
  const [searchValue, setSearchValue] = useState(query ?? "");
  const [isFocusWithin, setIsFocusWithin] = useState(false);
  const [isGenerallyFocused, setIsGenerallyFocused] = useState(false);
  const [loadingVenueId, setLoadingVenueId] = useState<string | null>(null);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(
    null,
  );
  const [keyboardSelectedIndex, setKeyboardSelectedIndex] = useState(0);
  const selectedItemRef = useRef<HTMLAnchorElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { hits } = useHits<VenueShape>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });
  const { refine: sortRefine } = useSortBy({
    items: [
      {
        label: "Default",
        value: searchIndexes.default,
      },
      {
        label: "Name (asc)",
        value: searchIndexes.sortedByName,
      },
    ],
  });
  const applySearch = useCallback(
    (search: string) => {
      setKeyboardSelectedIndex(0);
      setSearchValue(search);
      refine(search);
    },
    [setSearchValue, refine],
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsGenerallyFocused(isFocused || isFocusWithin);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isFocusWithin, isFocused]);

  useEffect(() => {
    function handleFocus(ev: FocusEvent) {
      setIsFocusWithin(popperElement?.contains(ev.target as Node) ?? false);
    }

    document.body.addEventListener("focus", handleFocus, true);
    return () => {
      document.body.removeEventListener("focus", handleFocus, true);
    };
  });

  const handleEnter = () => {
    selectedItemRef.current?.focus();
    selectedItemRef.current?.click();
  };

  const handleArrowUp = () => {
    setKeyboardSelectedIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleArrowDown = () => {
    setKeyboardSelectedIndex((prev) =>
      Math.min(prev + 1, hits.length, MAX_SEARCH_HITS),
    );
  };

  return (
    <div
      ref={setReferenceElement}
      className={cn(
        "relative z-10 flex w-full max-w-[660px] rounded-lg border-2 border-gray-200 px-3 py-2 shadow-sm md:w-3/4",
        isFocused || isGenerallyFocused ? "border-violet-600" : null,
      )}
    >
      <div className="relative grow">
        <button
          type="button"
          onClick={() => {
            inputElement?.focus();
          }}
          className="absolute inset-y-0 left-0 px-3 py-2"
        >
          <MagnifyingGlassIcon
            className={cs(
              "w-6",
              isFocused ? "text-violet-600" : "text-gray-300",
            )}
          />
        </button>
        <input
          ref={setInputElement}
          className={cs(
            "w-full py-2 pl-10 outline-none",
            searchValue.length === 0 && "pr-4",
            searchValue.length > 0 && "pr-14",
          )}
          role="presentation"
          placeholder="Search for a theatre"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          type="text"
          value={searchValue}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length > 0) {
              sortRefine(searchIndexes.default);
            } else {
              sortRefine(searchIndexes.sortedByName);
            }
            applySearch(value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleEnter();
            } else if (event.key === "ArrowUp") {
              handleArrowUp();
            } else if (event.key === "ArrowDown") {
              handleArrowDown();
            }
          }}
        />
        {/* <span className="absolute inset-y-0 right-0 px-4 py-2"> */}
        {searchValue.length > 0 ? (
          <button
            type="button"
            className="absolute inset-y-0 right-0 px-4 py-2 text-sm font-normal text-gray-400 hover:text-gray-600"
            onClick={() => {
              sortRefine(searchIndexes.sortedByName);
              applySearch("");
              inputElement?.focus();
            }}
          >
            clear
          </button>
        ) : null}
      </div>
      <div
        className="w-full px-4"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes}
      >
        <div
          className={cs(
            "w-full rounded border bg-white p-2 shadow",
            searchValue.length < 1 || !isGenerallyFocused
              ? "hidden"
              : "visible",
          )}
        >
          {hits.slice(0, MAX_SEARCH_HITS).map((hit, index) => (
            <Link
              ref={keyboardSelectedIndex === index ? selectedItemRef : null}
              href={`/theatre/${hit.id}`}
              key={hit.id}
              className={cs(
                keyboardSelectedIndex === index && "bg-violet-100",
                "block rounded p-3 text-sm outline-none hover:bg-violet-100 focus:outline-none focus:ring-2 focus:ring-violet-300",
              )}
              onClick={() => {
                setLoadingVenueId(hit.id);
              }}
            >
              <div className="font-bold">
                <Highlight
                  hit={hit}
                  attribute="name"
                  classNames={{
                    highlighted: "bg-violet-200 rounded",
                  }}
                />
                {loadingVenueId === hit.id ? (
                  <span className="ml-2 text-xs font-normal text-gray-400">
                    Opening...
                  </span>
                ) : null}
              </div>
              <div className="text-gray-500">
                {hit.city && hit.state ? (
                  <>
                    {hit.city}, {hit.state}
                  </>
                ) : null}
              </div>
            </Link>
          ))}
          {hits.length == 0 ? (
            <div className="p-3 text-center text-sm font-bold">
              No results found
            </div>
          ) : null}
          <Link
            href="/theatre/request/new"
            ref={
              keyboardSelectedIndex === Math.min(hits.length, MAX_SEARCH_HITS)
                ? selectedItemRef
                : null
            }
            className={cs(
              keyboardSelectedIndex ===
                Math.min(hits.length, MAX_SEARCH_HITS) && "bg-violet-100",
              "mt-5 block rounded p-3 text-xs font-bold outline-none hover:bg-violet-100 focus:outline-none focus:ring-2 focus:ring-violet-300",
              "flex items-center space-x-2",
            )}
          >
            <PlusIcon className="w-5" />
            <span>Request New Theatre</span>
          </Link>
        </div>
      </div>
      <Link
        href="/theatre"
        className={cn("btn-default flex items-center whitespace-nowrap")}
      >
        View all
      </Link>
    </div>
  );
};

export default HomePageV2;
